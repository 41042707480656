import React from 'react';
import './Contactus.css';
import location from '../../assets/images/location.png';
import envelop from '../../assets/images/envelop.png';
import mobilebutton from '../../assets/images/mobilebutton.png';
function Contactus() {
	return (
		<div className="content-wrapper">
			<div className="heading">
				<h2 className="m-auto">Contact Us</h2>
			</div>
			<div className="contactuswrapper">
				<div className="containers">
					<div className="heading-contact text-center">
						<label className="m-auto">
							We’d Love To <strong>Help You</strong>
						</label>
					</div>
					<div className="consumer-top  consumer-middle flex justify-center gap-100">
						<div className="contactus">
							<iframe
src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.2476611091033!2d77.14509887465105!3d28.682237281823355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03b4da260fb3%3A0x4b62a08f18158c2e!2sg%2018%2C%20B-3%2F2%2C%20Lawrence%20Rd%2C%20Dr%20Lohia%20Industrial%20Area%2C%20Keshav%20Puram%2C%20Tri%20Nagar%2C%20New%20Delhi%2C%20Delhi%2C%20110035!5e0!3m2!1sen!2sin!4v1698304783044!5m2!1sen!2sin"								height="100%"
								width="100%"
								allowfullscreen=""
								loading="lazy"
								referrerpolicy="no-referrer-when-downgrade"
								title='map'
							></iframe>
							<div className="contactusdetail">
								<div className="contactaddress">
									<div className="address">
										<label>Registered Address</label>
									</div>
									<div className="address">
										<img src={location} alt='location' />
										<p>GROUND FLOOR,SHOP NO.19, G-18/3 LAWRENCE ROAD, <br/> 
										NEW DELHI,NORTH WEST DELHI ,DELHI- 110035 </p>
								 	</div>
								</div>
								<div className="contactaddress">
									<div className="address">
										<label>Contact Us</label>
									</div>
									<div className="address">
										<img src={envelop} alt='envelop' />
										<p>
											<strong>Email Id :</strong>contactus.westonenterprises@gmail.com
										</p>
									</div>
									<div className="address">
										<img src={mobilebutton} alt='mobilebutton' />
										<p>
											<strong>Phone No :</strong>8859513583
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Contactus;
